import Icon from '@pasqal/core/ui/components/Icon';
import Stack from '@pasqal/core/ui/components/layout/Stack';
import { Text } from '@pasqal/core/ui/components/typography/Text';

import './betaTag.css';

export const BetaTag = () => {
  return (
    <Stack
      className="BetaTag"
      direction="horizontal"
      spacing="50"
      alignY="center"
    >
      <Icon
        icon="single-tap-gesture"
        color="brand-secondary"
        size="md"
        hasBackground
      />
      <Text variant="tiny" color="neutral-secondary">
        Beta
      </Text>
    </Stack>
  );
};
