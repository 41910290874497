import { getClasses } from '@pasqal/core/helpers/styles';
import {
  communityType,
  logoMark,
  logoType
} from '@pasqal/core/ui/components/PasqalLogo/pasqalLogoSvgSymbols';

import type { TSize } from '@pasqal/core/ui/components/types';
import '@pasqal/core/ui/components/PasqalLogo/pasqalLogo.css';

type TLogoSize = Extract<TSize, 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'>;

interface IProps {
  size?: TLogoSize;
  className?: string;
  testId?: string;
  variant?: 'mark' | 'full' | 'community';
  isInverted?: boolean;
}

interface IStyle {
  sizes: Record<TLogoSize, string>;
}

const styles: IStyle = {
  sizes: {
    sm: 'PasqalLogo--sm',
    md: 'PasqalLogo--md',
    lg: 'PasqalLogo--lg',
    xl: 'PasqalLogo--xl',
    '2xl': 'PasqalLogo--2xl',
    '3xl': 'PasqalLogo--3xl'
  }
};

export const PasqalLogo = ({
  size = 'md',
  className,
  testId,
  variant = 'full',
  isInverted = false
}: IProps) => {
  const css = getClasses([
    'PasqalLogo',
    size ? styles.sizes[size] : size,
    isInverted && 'PasqalLogo--inverted',
    className
  ]);

  const viewBoxFullWidth = variant === 'community' ? 830 : 118;
  // adapt the viewbox if we only need the logomark or not
  const viewBoxWidth = variant === 'full' ? 450 : viewBoxFullWidth;

  return (
    <svg
      className={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxWidth} 137`}
      data-testid={testId}
    >
      <title>Pasqal logo</title>
      {logoMark}
      {variant !== 'community' ? (
        logoType
      ) : (
        <>
          {logoType}
          {communityType}
        </>
      )}
    </svg>
  );
};
