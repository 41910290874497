import FileSaver from 'file-saver';
const { saveAs } = FileSaver;
import { jwtDecode } from 'jwt-decode';

import type {
  IApiJobResult,
  TProject,
  TProjectLastActivityDate
} from '@pasqal/core/api/types';
import type {
  ILessonsListItem,
  TBatchStatus,
  TFirstLastNamesPerson,
  TGivenFamilyNamesPerson,
  TJobStatus,
  TPerson,
  TWorkloadStatus
} from '@pasqal/core/types';
import type {
  ILearner,
  IUserLearnerData
} from '@pasqal/core/ui/components/types';

export const canCancelBatch = (status: TBatchStatus): boolean => {
  const validStatuses: TBatchStatus[] = ['PENDING', 'RUNNING'];
  return validStatuses.includes(status);
};

export const canCancelWorkload = (status: TWorkloadStatus): boolean => {
  const validStatuses: TWorkloadStatus[] = ['PENDING'];
  return validStatuses.includes(status);
};

export const canDownloadWorkload = (status: TWorkloadStatus): boolean => {
  const validStatuses: TWorkloadStatus[] = ['DONE'];
  return validStatuses.includes(status);
};

export const canCompleteBatch = (
  complete: boolean,
  status: TBatchStatus
): boolean => {
  // A batch can be marked as complete only if it is not already marked
  // and if its status is PENDING or RUNNING
  const validStatuses: TBatchStatus[] = ['PENDING', 'RUNNING'];
  return !complete && validStatuses.includes(status);
};

export const canCancelJob = (status: TJobStatus): boolean => {
  // A job can be canceled only if its status is PENDING
  const validStatuses: TJobStatus[] = ['PENDING'];
  return validStatuses.includes(status);
};

export const isQuantumDiscoveryPackage = (packageName: string | undefined) => {
  if (!packageName) {
    return false;
  }
  return /^QUANTUM_DISCOVERY/.test(packageName);
};

const downloadJsonFile = (
  data: IApiJobResult['counter'] | IApiJobResult,
  filename: string
) => {
  // Ensure a cleanly formatted JSON output
  const formattedData = JSON.stringify(data, null, 2);
  const blob = new Blob([formattedData], {
    type: 'text/plain;charset=utf-8'
  });
  saveAs(blob, filename);
};

export const downloadJobResult = (data: IApiJobResult, id: string) => {
  downloadJsonFile(data, `job_${id}_results.json`);
};

export const downloadBatchResult = (
  data: IApiJobResult['counter'],
  id: string
) => {
  downloadJsonFile(data, `batch_${id}_results.json`);
};

export const downloadWorkloadResult = (data: IApiJobResult, id: string) => {
  downloadJsonFile(data, `workload_${id}_results.json`);
};

let counter = 0;
export const getUniqueId = (prefix = '') => `${prefix}${++counter}`;

/* Transform an array of object into an "indexed" object . The index
 should be one of the property of the array elements.
  Example:
    const a = [{
      id: 'xxx',
      value: 'hello'
    },
    {
      id: 'yyy',
      value: 'world'
    }]

    // Normalize by id
    const normalized = normalizeData(a, 'id')

    => {
        'xxx': {
          id: 'xxx',
          value: 'hello'
        },
        'yyy': {
          id: 'yyy',
          value: 'world'
        }
      }
*/
export const normalizeData = <T extends Record<string, any>>(
  data: T[],
  key: keyof T
): Record<string | number, T> => {
  return data.reduce(
    (result, item) => {
      result[item[key]] = item;
      return result;
    },
    {} as Record<string | number, T>
  );
};

export const sortingDescData = <T>(data: T[], key: string) => {
  return data.sort((a, b) => (b[key] > a[key] ? 1 : -1));
};

// inspired by https://thewebdev.info/2021/03/06/how-to-deep-merge-javascript-objects/
// Make recursively a deep merge between two objects
// Source: Object to merge
// Target: Base object in which Source is merged
export const deepMerge = (target, source) => {
  // Helper function to test if an element is an object
  const isObject = (obj) => obj && typeof obj === 'object';

  // Copy target and source for immutability
  target = { ...target };
  source = { ...source };

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  // Iterate across each properties of the source object
  // and merge or override their values with the target object
  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    // If the property is an object, merge recursively the two objects
    if (
      !Array.isArray(sourceValue) &&
      isObject(targetValue) &&
      isObject(sourceValue)
    ) {
      target[key] = deepMerge(targetValue, sourceValue);
    } else {
      // For other types, override the corresponding target value
      target[key] = sourceValue;
    }
  });

  return target;
};

// Capitalize first letter of a string
export const strCapitalizeFirstLetter = (str) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// 'Slugify' a space delimited string
// ex: Hello beautiful world => hello-beautiful-world
export const slugify = (str: string): string =>
  str.replace(/\s+/g, '-').toLowerCase();

function isFirstLastNamesPerson(
  person: TPerson
): person is TFirstLastNamesPerson {
  return (
    (person as TFirstLastNamesPerson).firstName !== undefined ||
    (person as TFirstLastNamesPerson).lastName !== undefined
  );
}

const normalizePersonNames = (person: TPerson) => {
  if (isFirstLastNamesPerson(person)) {
    return person;
  }
  return {
    firstName: person.givenName,
    lastName: person.familyName
  };
};

// Get initials either if a name has 2 parts or just 1 (mononymous people or companies)
export const getInitialsFromName = (person: TPerson) => {
  const { firstName, lastName } = normalizePersonNames(person);
  let initials = '';

  if (!firstName && lastName) {
    // No first name
    initials = lastName.substring(0, 2);
  } else if (firstName && !lastName) {
    // No last name
    initials = firstName.substring(0, 2);
  } else if (firstName && lastName) {
    // Full name
    initials = firstName.charAt(0) + lastName.charAt(0);
  }

  return initials.toUpperCase();
};

// Get full name from either first + last name, or only one of the 2 for mononymous people
export const getFullName = (person: TPerson) => {
  const { firstName, lastName } = normalizePersonNames(person);
  return [firstName, lastName].filter((v) => v).join(' ');
};

export const arrayUnique = <T>(a: T[]) => Array.from(new Set(a));

// Sort an array by an array of values
export const sortByArrayOrder = (
  arrToSort: any[],
  order: string[],
  orderKey?: string
) => {
  if (orderKey) {
    // This is an array of objects
    return [...arrToSort].sort(
      (a, b) => order.indexOf(a[orderKey]) - order.indexOf(b[orderKey])
    );
  }

  // This is an array of strings
  return [...arrToSort].sort((a, b) => order.indexOf(a) - order.indexOf(b));
};

export const arrayEquals = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const kebabCase = (str) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase();

export const camelCase = (str) =>
  str
    .toLowerCase()
    .replace('_', ' ')
    .replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');

export const computeUserProgress = (
  allLessons: ILessonsListItem[],
  learner: ILearner
): IUserLearnerData => {
  const progressPercent = Math.floor(
    (allLessons.filter(
      (lessonItem) => learner.progress[lessonItem.uid] !== undefined
    ).length /
      allLessons.length) *
      100
  );

  const { firstName, lastName, id, projectId, progress } = learner;

  return {
    id,
    firstName,
    lastName,
    progress,
    progressPercent: !isNaN(progressPercent) ? progressPercent : 0,
    lessonCount: allLessons.length,
    projectId
  };
};

// Convert myString to My String
export const camelCaseToTitleCase = (str: string): string => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const isValidURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_err) {
    return false;
  }
};

// Returns an object with array elements grouped in multiple arrays indexed by a key,
// which is the result of a key function applied on each element
export const groupBy = <T>(
  array: T[] = [],
  keyFunction: (element: T) => any
): Record<string, T[]> =>
  array.reduce((groups, current) => {
    const keyValue = String(keyFunction(current));
    return {
      ...groups,
      [keyValue]: [...(groups[keyValue] || []), current]
    };
  }, {});

// Returns a number value rounded down to the specified precision.
// ex:
// floorWithPrecision(1.28, 0) = 1
// floorWithPrecision(1.28, 1) = 1.2
// floorWithPrecision(1.28, 2) = 1.28
export const floorWithPrecision = (
  value: number,
  precision: number = 0
): number => {
  const power = Math.pow(10, precision);
  return Math.floor(value * power) / power;
};

export const convertBlobToText = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(blob);
  });
};

// Method to get the full name of the user
export const getOwnerName = (
  userId: string,
  membersData?: Record<string, TGivenFamilyNamesPerson>
) => {
  const user = membersData?.[userId];
  return user ? getFullName(user) : 'Unknown user';
};

// Method to get the name of the project
export const getProjectName = (
  projectId: string,
  projectsData?: TProject[]
) => {
  return projectsData?.find((project) => project.id === projectId)?.name || '';
};

// Method to get the priority of the project
export const getProjectPriority = (
  projectId: string,
  projectsData?: TProject[]
) => {
  return (
    projectsData?.find((project) => project.id === projectId)?.queuePriority ||
    ''
  );
};

export const getProjectLastActivity = (
  projectId: string,
  data?: TProjectLastActivityDate[]
) => {
  return (
    data?.find((item) => item.projectId === projectId)?.lastActivityDate || ''
  );
};

export const decodeJWToken = (token: string) => jwtDecode(token);
