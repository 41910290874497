import { getClasses } from '@pasqal/core/helpers/styles';
import Icon from '@pasqal/core/ui/components/Icon/';
import Avatar from '@pasqal/core/ui/components/avatar/Avatar';
import Dropdown from '@pasqal/core/ui/components/dropdown/Dropdown';
import DropdownMenu from '@pasqal/core/ui/components/dropdown/DropdownMenu';
import Stack from '@pasqal/core/ui/components/layout/Stack/';

import type { TPerson } from '@pasqal/core/types';
import type { IDropdownMenuItem } from '@pasqal/core/ui/components/dropdown/DropdownMenu';

import '@pasqal/core/ui/components/dropdown/SettingsDropdown/settingsDropdown.css';

interface IDropdownButtonProps {
  user: TPerson;
  testId?: string;
}

const DropdownButton = ({ user, testId, ...rest }: IDropdownButtonProps) => (
  <button
    className="SettingsDropdown-button"
    {...rest}
    data-testid={
      testId !== undefined ? `${testId}-dropdown-menu-toggle-button` : undefined
    }
  >
    <Stack direction="horizontal" alignY="center" spacing="50">
      <Avatar className="SettingsDropdown-avatar" person={user} size="lg" />
      <Icon
        className="SettingsDropdown-buttonIcon"
        icon="chevron-down"
        color="neutral-primary"
      />
    </Stack>
  </button>
);

interface ISettingsDropdownProps {
  user: TPerson;
  className?: string;
  navItems: IDropdownMenuItem[];
  testId?: string;
}

export const SettingsDropdown = ({
  user,
  className,
  navItems,
  testId
}: ISettingsDropdownProps) => {
  const css = getClasses(['SettingsDropdown', className]);

  return (
    <Dropdown
      id="settings-dropdown"
      ariaLabel="Open settings menu"
      className={css}
      button={<DropdownButton user={user} testId={testId} />}
      placement="bottom"
      dropdownContent={
        <DropdownMenu options={navItems} ariaLabel="Settings menu" />
      }
      alignToEnd
      testId={testId}
    />
  );
};
