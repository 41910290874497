import { getClasses } from '@pasqal/core/helpers/styles';
import Icon from '@pasqal/core/ui/components/Icon';
import Checkbox from '@pasqal/core/ui/components/form/Checkbox';
import Stack from '@pasqal/core/ui/components/layout/Stack';

import type { TIcon } from '@pasqal/core/ui/components/Icon';

import '@pasqal/core/ui/components/dropdown/DropdownMenu/dropdownMenu.css';

export type TOptionStatus = 'default' | 'warning' | 'error';

export interface IDropdownMenuItem {
  label: string;
  value?: string | number | boolean;
  icon?: TIcon;
  status?: TOptionStatus;
  isDisabled?: boolean;
  isSelected?: boolean;
  hasSeparator?: boolean;
  onClick: (data?: any) => void;
  testId?: string;
}

interface IProps {
  className?: string;
  options: IDropdownMenuItem[];
  role?: string;
  optionRole?: string;
  ariaLabel?: string;
  hasSelectOptionIcon?: boolean;
  isSelectedOptionClickable?: boolean;
  testId?: string;
  isMultiple?: boolean;
}

interface IOptionStyles {
  status: Record<TOptionStatus, string>;
}

const optionStyles: IOptionStyles = {
  status: {
    default: 'DropdownMenu-option--default',
    warning: 'DropdownMenu-option--warning',
    error: 'DropdownMenu-option--error'
  }
};

export const DropdownMenu = ({
  className,
  options,
  role = 'menu',
  optionRole = 'menuitem',
  ariaLabel,
  hasSelectOptionIcon = false,
  isSelectedOptionClickable = false,
  testId,
  isMultiple = false
}: IProps) => {
  const css = getClasses(['DropdownMenu', className]);
  const hasTestId = testId !== undefined;

  return (
    <ul
      className={css}
      role={role}
      aria-orientation="vertical"
      aria-label={ariaLabel}
      data-testid={testId && `${testId}-items`}
    >
      {options.map(
        (
          {
            label,
            value,
            icon,
            status = 'default',
            onClick,
            isDisabled = false,
            isSelected = false,
            hasSeparator = false,
            testId: customTestId
          },
          index
        ) => {
          const css = getClasses([
            'DropdownMenu-option',
            optionStyles.status[status],
            hasSelectOptionIcon && 'DropdownMenu-option--withSelectIcon',
            isDisabled && 'is-disabled',
            isSelected && 'is-selected',
            isSelected && !isSelectedOptionClickable && 'is-notClickable',
            hasSeparator && 'has-separator'
          ]);
          const displayedIcon = hasSelectOptionIcon ? 'check' : icon;
          const optionTestId =
            customTestId !== undefined
              ? customTestId
              : hasTestId
                ? `${testId}-item-${index}`
                : undefined;

          return (
            <li
              className={css}
              key={`item-${index}`}
              tabIndex={0}
              role={optionRole}
              onClick={
                !isDisabled && (isSelectedOptionClickable || !isSelected)
                  ? onClick
                  : undefined
              }
              data-value={value?.toString()}
              data-testid={optionTestId}
            >
              <Stack direction="horizontal" spacing="200" alignY="center">
                {!isMultiple ? (
                  <>
                    {displayedIcon && (
                      <div className="DropdownMenu-optionIcon">
                        <Icon icon={displayedIcon} />
                      </div>
                    )}
                  </>
                ) : (
                  <Checkbox isChecked={isSelected} />
                )}

                <span
                  className="DropdownMenu-optionLabel"
                  data-testid={optionTestId && `${optionTestId}-label`}
                >
                  {label}
                </span>
              </Stack>
            </li>
          );
        }
      )}
    </ul>
  );
};
