import { getFullName } from '@pasqal/core/helpers/data';
import Drawer from '@pasqal/core/ui/components/Drawer';
import PasqalLogo from '@pasqal/core/ui/components/PasqalLogo';
import Avatar from '@pasqal/core/ui/components/avatar/Avatar';
import Button from '@pasqal/core/ui/components/button/Button';
import Stack from '@pasqal/core/ui/components/layout/Stack';
import { Heading, Text } from '@pasqal/core/ui/components/typography/Text';
import { checkIsOnboarded } from '~/utils/auth';

import type { IDrawerProps } from '@pasqal/core/ui/components/Drawer';
import type { IApiUser } from '~/@types/api/user';

import './topHeaderDrawerMenu.css';

interface IProps extends Omit<IDrawerProps, 'children'> {
  user?: IApiUser;
  onSignInClick: () => void;
  onSignUpClick: () => void;
  onSignOutClick: () => void;
  testId?: string;
}

export const TopHeaderDrawerMenu = ({
  user,
  isOpen,
  onSignInClick,
  onSignUpClick,
  onSignOutClick,
  onClose
}: IProps) => {
  return (
    <Drawer
      className="TopHeaderDrawerMenu"
      role="menu"
      ariaLabel="Main navigation"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack spacing="400" isDispatchingY>
        <Stack className="TopHeaderDrawerMenu-main" spacing="400">
          {user && checkIsOnboarded(user) ? (
            <Stack alignX="center" spacing="200">
              <Avatar person={user} size="xl" />
              <Stack alignX="center" spacing="0">
                <Heading as="span" variant="heading3">
                  {getFullName(user)}
                </Heading>
                <Text color="neutral-secondary">{user.email}</Text>
              </Stack>
            </Stack>
          ) : (
            <PasqalLogo size="md" variant="community" />
          )}

          <Stack
            as="nav"
            spacing="0"
            className="TopHeaderDrawerMenu-box"
            alignX="start"
          >
            <Button as="a" href="/" size="md" variant="text" iconLeft="home">
              Home
            </Button>
            <Button
              as="a"
              href="/learning"
              size="md"
              variant="text"
              iconLeft="planet"
            >
              Learning
            </Button>
            <Button
              as="a"
              href="/resources"
              size="md"
              variant="text"
              iconLeft="doc"
            >
              Resources
            </Button>
            <Button
              as="a"
              href="/tools"
              size="md"
              variant="text"
              iconLeft="tool"
            >
              Tools
            </Button>
          </Stack>

          {user && checkIsOnboarded(user) && (
            <Stack as="nav" className="TopHeaderDrawerMenu-box" alignX="start">
              <Button
                as="a"
                href="/profile"
                size="md"
                variant="text"
                iconLeft="user"
              >
                Profile
              </Button>
            </Stack>
          )}
          {user && (
            <Stack className="TopHeaderDrawerMenu-box" alignX="start">
              <Button
                variant="text"
                size="md"
                iconLeft="log-out"
                onClick={onSignOutClick}
              >
                Log out
              </Button>
            </Stack>
          )}
        </Stack>
        {!user && (
          <Stack className="TopHeaderDrawerMenu-footer" spacing="150">
            <Button variant="primary" size="md" onClick={onSignUpClick}>
              Sign up
            </Button>
            <Button variant="outlined" size="md" onClick={onSignInClick}>
              Log in
            </Button>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};
